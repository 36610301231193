.main-footer {
		position: fixed;
		bottom:0px;
		width:100%;
}

button {
		margin-left: 5px;
}

.table-actions {
		width: 150px;
}

.radial-chart {
	position: relative;
	display: inline-block;
	transition: all 0.3s ease-in;
}
.radial-chart.no-progress .radial-chart-progress {
	opacity: 0;
}
.radial-chart-total {
	opacity: 0.2;
}
.radial-chart-progress {
	-webkit-transform: rotate(90deg);
	        transform: rotate(90deg);
	-webkit-transform-origin: center;
	        transform-origin: center;
	transition: all 0.6s cubic-bezier(0.58, 0.16, 0.5, 1.14);
	transition-delay: 0.3s;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.bottom-menu-sem-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.bottom-menu-sem-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.radial-chart {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  transition: all 0.3s ease-in;
  background-color: transparent;
}
.radial-chart.no-progress .radial-chart-progress {
  opacity: 0;
}
.radial-chart-total {
  opacity: 0.3;
}
.radial-chart-progress {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  -webkit-transform-origin: center;
          transform-origin: center;
  transition: all 0.6s cubic-bezier(0.58, 0.16, 0.5, 1.14);
  transition-delay: 0.3s;
}

.wrapper {
	background-color: #fff!important;
}

.login-box-body {
	background-color: #eee;
}

.login-box button {
	margin-left: 0px;
}

.login-box a:hover {
	cursor: pointer;
}

